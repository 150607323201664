export const PortionSizes = ['SMALL', 'MEDIUM', 'LARGE'] as const;
export type PortionSize = (typeof PortionSizes)[number];

export const DefaultPortionSizeNameMap = {
  SMALL: 'Happy Belly - $7.50/meal, 2.5oz protein',
  MEDIUM: 'Achieve - $11/meal, 4oz protein',
  LARGE: 'Pro-Cut - $15/meal, 7oz protein',
} as const;

export const PiperPortionSizeNameMap = {
  SMALL: 'Happy Belly — $8/meal, 2.5oz protein',
} as const;

export const aHaPortionSizeNameMap = {
  SMALL: 'Happy Belly — $8/meal, 2.5oz protein',
  MEDIUM: 'Achieve — $10/meal, 4oz protein',
  LARGE: 'Pro-Cut — $14/meal, 7oz protein',
} as const;

export const DefaultEmployeePortionSizeNameMap = {
  SMALL: '',
  MEDIUM: 'Achieve - $7.50/meal, 4oz protein',
  LARGE: 'Pro-Cut - $11.50/meal, 10oz protein',
} as const;

export const RedAppleEmployeePortionSizeNameMap = {
  MEDIUM: 'Achieve - $7.50/meal, 4oz protein',
  LARGE: 'Pro-Cut - $11.50/meal, 10oz protein',
} as const;

export const PiperEmployeePortionSizeNameMap = {
  MEDIUM: 'Employee Achieve — $11/meal, 4oz protein',
} as const;

export const aHaEmployeePortionSizeNameMap = {
  MEDIUM: 'Employee Achieve — $7/meal, 4oz protein',
} as const;

export const OCathletesPortionSizeNameMap = {
  LARGE: 'Pro-Cut - $16/meal, 7oz protein',
} as const;

export enum LocationIDs {
  Default = 0,
  FairmontSJC = 603,
  FairmontAnaheimHills = 632,
  FairmontHistoricAnaheim = 628,
  FairmontPrepHighSchool = 616,
  FairmontPrepBoarding = 653,
  PiperDev = 663,
  Piper = 703,
  PiperPreschoolLosAngeles = 722,
  PiperPreschoolManhattanBeach = 723,
  PiperPreschoolSantaMonica = 721,
  aHaDev = 664,
  aHa = 704,
  RedApple = 668,
  RedAppleDev = 662,
  OCathletesDev = 665,
  OCathletes = 715,
}

export const PortionSizeNameMapByLocation: Record<
  number,
  { DEFAULT: Partial<Record<PortionSize, string>>; STAFF?: Partial<Record<PortionSize, string>> }
> = {
  [LocationIDs.Default]: {
    DEFAULT: {
      SMALL: DefaultPortionSizeNameMap.SMALL,
      MEDIUM: DefaultPortionSizeNameMap.MEDIUM,
      LARGE: DefaultPortionSizeNameMap.LARGE,
    },
    STAFF: {
      MEDIUM: DefaultEmployeePortionSizeNameMap.MEDIUM,
      LARGE: DefaultEmployeePortionSizeNameMap.LARGE,
    },
  },
  [LocationIDs.FairmontSJC]: {
    DEFAULT: {
      SMALL: DefaultPortionSizeNameMap.SMALL,
      MEDIUM: DefaultPortionSizeNameMap.MEDIUM,
      LARGE: DefaultPortionSizeNameMap.LARGE,
    },
    STAFF: {
      MEDIUM: DefaultEmployeePortionSizeNameMap.MEDIUM,
      LARGE: DefaultEmployeePortionSizeNameMap.LARGE,
    },
  },
  [LocationIDs.FairmontAnaheimHills]: {
    DEFAULT: {
      SMALL: DefaultPortionSizeNameMap.SMALL,
      MEDIUM: DefaultPortionSizeNameMap.MEDIUM,
      LARGE: DefaultPortionSizeNameMap.LARGE,
    },
    STAFF: {
      MEDIUM: DefaultEmployeePortionSizeNameMap.MEDIUM,
      LARGE: DefaultEmployeePortionSizeNameMap.LARGE,
    },
  },
  [LocationIDs.FairmontHistoricAnaheim]: {
    DEFAULT: {
      SMALL: DefaultPortionSizeNameMap.SMALL,
      MEDIUM: DefaultPortionSizeNameMap.MEDIUM,
      LARGE: DefaultPortionSizeNameMap.LARGE,
    },
    STAFF: {
      MEDIUM: DefaultEmployeePortionSizeNameMap.MEDIUM,
      LARGE: DefaultEmployeePortionSizeNameMap.LARGE,
    },
  },
  [LocationIDs.FairmontPrepHighSchool]: {
    DEFAULT: {
      MEDIUM: DefaultPortionSizeNameMap.MEDIUM,
      LARGE: DefaultPortionSizeNameMap.LARGE,
    },
    STAFF: {
      MEDIUM: DefaultEmployeePortionSizeNameMap.MEDIUM,
      LARGE: DefaultEmployeePortionSizeNameMap.LARGE,
    },
  },
  [LocationIDs.FairmontPrepBoarding]: {
    DEFAULT: {
      SMALL: DefaultPortionSizeNameMap.SMALL,
      MEDIUM: DefaultPortionSizeNameMap.MEDIUM,
      LARGE: DefaultPortionSizeNameMap.LARGE,
    },
    STAFF: {
      MEDIUM: DefaultEmployeePortionSizeNameMap.MEDIUM,
      LARGE: DefaultEmployeePortionSizeNameMap.LARGE,
    },
  },
  [LocationIDs.PiperDev]: {
    DEFAULT: {
      SMALL: PiperPortionSizeNameMap.SMALL,
    },
    STAFF: {
      MEDIUM: PiperEmployeePortionSizeNameMap.MEDIUM,
    },
  },
  [LocationIDs.Piper]: {
    DEFAULT: {
      SMALL: PiperPortionSizeNameMap.SMALL,
    },
    STAFF: {
      MEDIUM: PiperEmployeePortionSizeNameMap.MEDIUM,
    },
  },
  [LocationIDs.PiperPreschoolLosAngeles]: {
    DEFAULT: {
      SMALL: PiperPortionSizeNameMap.SMALL,
    },
    STAFF: {
      MEDIUM: PiperEmployeePortionSizeNameMap.MEDIUM,
    },
  },
  [LocationIDs.PiperPreschoolManhattanBeach]: {
    DEFAULT: {
      SMALL: PiperPortionSizeNameMap.SMALL,
    },
    STAFF: {
      MEDIUM: PiperEmployeePortionSizeNameMap.MEDIUM,
    },
  },
  [LocationIDs.PiperPreschoolSantaMonica]: {
    DEFAULT: {
      SMALL: PiperPortionSizeNameMap.SMALL,
    },
    STAFF: {
      MEDIUM: PiperEmployeePortionSizeNameMap.MEDIUM,
    },
  },
  [LocationIDs.aHaDev]: {
    DEFAULT: {
      SMALL: aHaPortionSizeNameMap.SMALL,
      MEDIUM: aHaPortionSizeNameMap.MEDIUM,
      LARGE: aHaPortionSizeNameMap.LARGE,
    },
    STAFF: {
      MEDIUM: aHaEmployeePortionSizeNameMap.MEDIUM,
    },
  },
  [LocationIDs.aHa]: {
    DEFAULT: {
      SMALL: aHaPortionSizeNameMap.SMALL,
      MEDIUM: aHaPortionSizeNameMap.MEDIUM,
      LARGE: aHaPortionSizeNameMap.LARGE,
    },
    STAFF: {
      MEDIUM: aHaEmployeePortionSizeNameMap.MEDIUM,
    },
  },
  [LocationIDs.RedApple]: {
    DEFAULT: {
      SMALL: aHaPortionSizeNameMap.SMALL,
      MEDIUM: aHaPortionSizeNameMap.MEDIUM,
      LARGE: aHaPortionSizeNameMap.LARGE,
    },
    STAFF: {
      MEDIUM: RedAppleEmployeePortionSizeNameMap.MEDIUM,
      LARGE: RedAppleEmployeePortionSizeNameMap.LARGE,
    },
  },
  [LocationIDs.RedAppleDev]: {
    DEFAULT: {
      SMALL: aHaPortionSizeNameMap.SMALL,
      MEDIUM: aHaPortionSizeNameMap.MEDIUM,
      LARGE: aHaPortionSizeNameMap.LARGE,
    },
    STAFF: {
      MEDIUM: RedAppleEmployeePortionSizeNameMap.MEDIUM,
      LARGE: RedAppleEmployeePortionSizeNameMap.LARGE,
    },
  },
  [LocationIDs.OCathletesDev]: {
    DEFAULT: {
      LARGE: OCathletesPortionSizeNameMap.LARGE,
    },
    STAFF: {
      LARGE: OCathletesPortionSizeNameMap.LARGE,
    },
  },
  [LocationIDs.OCathletes]: {
    DEFAULT: {
      LARGE: OCathletesPortionSizeNameMap.LARGE,
    },
    STAFF: {
      LARGE: OCathletesPortionSizeNameMap.LARGE,
    },
  },
};

// Infer PortionSizeLocationMap from PortionSizeNameMapByLocation
const PortionSizeLocationMap: Record<string, Record<string, PortionSize[]>> = Object.entries(
  PortionSizeNameMapByLocation
).reduce((acc, [locationId, sizeMap]) => {
  acc[locationId] = Object.keys(sizeMap).reduce((innerAcc, type) => {
    innerAcc[type] = Object.keys(sizeMap[type as keyof typeof sizeMap] as Record<string, string>) as PortionSize[];
    return innerAcc;
  }, {} as Record<string, PortionSize[]>);
  return acc;
}, {} as Record<string, Record<string, PortionSize[]>>);

export const selectPortionSizesForLocation = (client_id?: number | null, type: 'STAFF' | 'DEFAULT' = 'DEFAULT') => {
  if (!client_id) return PortionSizes;

  const locationMap = PortionSizeLocationMap[client_id];
  return locationMap ? locationMap[type] || locationMap.DEFAULT : PortionSizes;
};

export const selectPortionLabelMapForLocation = (
  client_id?: number | null,
  type: 'STAFF' | 'DEFAULT' = 'DEFAULT'
): Partial<Record<PortionSize, string>> => {
  if (!client_id) return PortionSizeNameMapByLocation[LocationIDs.Default].DEFAULT;

  const locationMap = PortionSizeNameMapByLocation[client_id];
  return locationMap
    ? (locationMap[type] as Record<PortionSize, string>) || (locationMap.DEFAULT as Record<PortionSize, string>)
    : PortionSizeNameMapByLocation[LocationIDs.Default].DEFAULT;
};

export const selectPortionLabelForLocation = (
  portionSize: PortionSize,
  client_id?: number | null,
  type: 'STAFF' | 'DEFAULT' = 'DEFAULT'
) => {
  const labelMap = selectPortionLabelMapForLocation(client_id, type);
  return labelMap[portionSize as keyof typeof labelMap] || '';
};

export const selectPortionOptionsForLocation = (client_id?: number | null, type: 'STAFF' | 'DEFAULT' = 'DEFAULT') => {
  const portionSizes = selectPortionSizesForLocation(client_id, type);
  const labelMap = selectPortionLabelMapForLocation(client_id, type);
  return portionSizes.map((portionSize) => {
    return {
      label: labelMap[portionSize as keyof typeof labelMap] || '',
      value: portionSize,
    };
  });
};
